* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    color: var(--text-100);
    /* user-select: none; */
}

:root {
    --primary-100: #d4eaf7;
    --primary-200: #b6ccd8;
    --primary-300: #3b3c3d;
    --accent-100: #71c4ef;
    --accent-200: #00668c;
    --text-100: #1d1c1c;
    --text-200: #313d44;
    --bg-10: #ffffff;
    --bg-100: #fffefb;
    --bg-200: #f5f4f1;
    --bg-300: #cccbc8;
    --text-white: #fff;


    --mdb-red: #f44336;
    --mdb-pink: #e91e63;
    --mdb-purple: #9c27b0;
    --mdb-indigo: #3f51b5;
    --mdb-blue: #2196f3;
    --mdb-cyan: #00bcd4;
    --mdb-teal: #009688;
    --mdb-green: #4caf50;
    --mdb-yellow: #ffeb3b;
    --mdb-orange: #ff9800;
    --mdb-white: #fff;
    --mdb-black: #000;
    --mdb-gray: #757575;
    --mdb-gray-dark: #4f4f4f;
    --mdb-gray-50: #fbfbfb;
    --mdb-gray-100: #f5f5f5;
    --mdb-gray-200: #eeeeee;
    --mdb-gray-300: #e0e0e0;
    --mdb-gray-400: #bdbdbd;
    --mdb-gray-500: #9e9e9e;
    --mdb-gray-600: #757575;
    --mdb-gray-700: #616161;
    --mdb-gray-800: #4f4f4f;
    --mdb-gray-900: #262626;
    /* --mdb-primary: var(--primary-100) !important; */
    --mdb-secondary: #9fa6b2;
    --mdb-success: #14a44d;
    --mdb-danger: #dc4c64;
    --mdb-warning: #e4a11b;
    --mdb-info: #54b4d3;
    --mdb-light: #fbfbfb;
    --mdb-dark: #332d2d;
    --mdb-primary-rgb: 59, 113, 202;
    --mdb-secondary-rgb: 159, 166, 178;
    --mdb-success-rgb: 20, 164, 77;
    --mdb-danger-rgb: 220, 76, 100;
    --mdb-warning-rgb: 228, 161, 27;
    --mdb-info-rgb: 84, 180, 211;
    --mdb-light-rgb: 251, 251, 251;
    --mdb-dark-rgb: 51, 45, 45;
    --mdb-white-rgb: 255, 255, 255;
    --mdb-black-rgb: 0, 0, 0;
    --mdb-body-color-rgb: 79, 79, 79;
    --mdb-body-bg-rgb: 255, 255, 255;
    --mdb-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --mdb-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --mdb-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --mdb-body-font-family: var(--mdb-font-roboto);
    --mdb-body-font-size: 1rem;
    --mdb-body-font-weight: 400;
    --mdb-body-line-height: 1.6;
    --mdb-body-color: #4f4f4f;
    --mdb-body-bg: #fff;
    --mdb-border-width: 1px;
    --mdb-border-style: solid;
    --mdb-border-color: #e0e0e0;
    --mdb-border-color-translucent: rgba(0, 0, 0, 0.175);
    --mdb-border-radius: 0.25rem;
    --mdb-border-radius-sm: 0.25rem;
    --mdb-border-radius-lg: 0.5rem;
    --mdb-border-radius-xl: 1rem;
    --mdb-border-radius-2xl: 2rem;
    --mdb-border-radius-pill: 50rem;
    --mdb-link-color: #3b71ca;
    --mdb-link-hover-color: #386bc0;
    --mdb-code-color: #e91e63;
    --mdb-highlight-bg: #fff9c4;

    --body_background: white;
    --body_color: black;
    --link_color: navy;
}

[data-theme="dark"] {
    --primary-100: #2C3A4F;
    --primary-200: #56647b;
    --primary-300: #b4c2dc;
    --accent-100: #FF4D4D;
    --accent-200: #ffecda;
    --text-100: #FFFFFF;
    --text-200: #e0e0e0;
    --bg-10: #292e3b;
    --bg-100: #1A1F2B;
    --bg-200: #292e3b;
    --bg-300: #414654;
    --mdb-white: #fff;
    --text-white: #fff;


    .navImg {
        background-image: url(../Assets/Logos/Group\ 3.png);
    }

}

.w-5 {
    width: 5% !important;
}

.w-10 {
    width: 10% !important;
}

.w-15 {
    width: 15% !important;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-30 {
    width: 30% !important;
}

.w-35 {
    width: 35% !important;
}

.w-40 {
    width: 40% !important;
}

.w-45 {
    width: 45% !important;
}

.w-50 {
    width: 50% !important;
}

.w-55 {
    width: 55% !important;
}

.w-60 {
    width: 60% !important;
}

.w-65 {
    width: 65% !important;
}

.w-70 {
    width: 70% !important;
}

.w-75 {
    width: 75% !important;
}

.w-80 {
    width: 80% !important;
}

.w-85 {
    width: 85% !important;
}

.w-90 {
    width: 90% !important;
}

.w-95 {
    width: 95% !important;
}

.w-100 {
    width: 100% !important;
}

::-webkit-scrollbar {
    width: 8px;
    border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--bg-200);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--bg-300);
    border-radius: 5px;
}

body {
    background-color: var(--bg-100);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* .navbar {
    background-color: var(--bg-200);
    position: sticky;
    top: 0px;
    z-index: 99; 
    padding: 10px 100px;
}

.navbar .nav-item .nav-link {
    color: var(--text-100) !important;
} */
.navbar {
    height: 100px !important;
    background-color: var(--bg-100) !important;
    color: var(--color-white) !important;
    z-index: 10;
    position: sticky !important;
    top: 0px !important;
    padding: 0 150px;

}

.navbar .nav-link {
    color: var(--color-white) !important;
}

.dropdown-menu {
    border-radius: 0px !important;
}

.nav-search {
    height: 51px;
    background-color: var(--mdb-primary);
    color: var(--text-white);
    position: absolute;
    top: 0;
    right: 0;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 0px 150px;
    z-index: 9999;
}

.nav-search a {
    color: var(--text-white) !important;
}

.nav-search .form-control,
.nav-search .input-group-text {
    border-radius: 0px !important;
}

.nav-search .form-control {
    border-right: none !important;
    outline: none !important;
}



.navbar-nav {
    height: 100px !important;
    display: flex !important;
    align-items: end !important;
    z-index: 999;
    position: relative;

}

.navbar-collapse {
    background-color: var(--bg-100) !important;

}

.nav-item {
    margin-right: 25px;
    margin-bottom: 5px;
}

.dropdown {
    cursor: pointer !important;
}

.dropdown-item:hover {
    border-radius: 0px !important;
}

.mainPage {
    width: 100%;
    min-height: calc(100vh - 125px);
    background-image: url(../Assets/Images/Background.png);
    background-repeat: no-repeat;
    background-color: var(--bg-100);
    background-size: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
}

.padding-container {
    padding: 0 100px;
}

.CarouselImageSize img {
    width: 100%;
    min-height: calc(100vh - 70px);
    object-fit: cover;
}

.posterImage img {
    width: 80%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

h1 {
    font-size: 3.44rem;
}

.section-2 {
    width: 100%;
    min-height: calc(100vh - 57px);
    background-image: url(../Assets/Images/Background2.png);
    background-repeat: no-repeat;
    background-color: var(--bg-100);
    background-size: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
}

.section {
    width: 100%;
    /* height: calc(100vh - 57px); */
    background-image: url(../Assets/Images/Background2.png);
    background-repeat: no-repeat;
    background-color: var(--bg-100);
    background-size: 100%;
    object-fit: cover;
    display: flex;
}

.section-3 {
    width: 100%;
    background-image: url(../Assets/Images/Background2.png);
    background-repeat: no-repeat;
    background-color: var(--bg-100);
    background-size: 100%;
    object-fit: cover;
}

.section-4 {
    width: 100%;
    /* height: calc(100vh - 57px); */
    background-image: url(../Assets/Images/Background.png);
    background-repeat: repeat-y;
    background-color: var(--bg-100);
    background-size: 100%;
    object-fit: cover;
}

.Products {
    width: 33%;
    height: 350px;
    background-color: var(--primary-200);
    border-radius: 1px;
    margin-top: 13px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Products img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    position: absolute;
}

.Products p {
    position: absolute;
    z-index: 99;
    background-color: var(--bg-300);
    padding: 10px;
    text-align: center;
    margin: 0;
    border-radius: 8px;
    font-size: 1.3em;
    font-weight: bold;
}

.Products2 {
    /* width: 24%; */
    background-color: var(--primary-100);
    margin-top: 25px;
    /* margin-right: 22px; */
}

.Products2:nth-child(4),
.Products2:nth-child(8),
.Products2:nth-child(12),
.Products2:nth-child(16),
.Products2:nth-child(20),
.Products2:nth-child(24) {
    margin-right: 0px;
}

.Certifications img {
    width: 250px;
    mix-blend-mode: multiply;
    object-fit: cover;
}

.Certifications2 img {
    width: 200px;
    mix-blend-mode: multiply;
    object-fit: cover;
}

.Certifications {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

}

.Certifications2 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.Certifications2 div {
    margin-right: 30px;
}

.Certificate {
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-100);
    flex-direction: column;
    height: 320PX;
    margin-top: 25px;
    padding: 15px;
    overflow: hidden;
    position: relative;
}


.Certificate p {
    width: 80%;
    font-size: 15px;
    text-align: justify;
    text-indent: 50px;
}

.Certificate small {
    position: absolute;
    bottom: 5px;
    right: 10px;
}

.HeroPoster {
    background-image: url(../Assets/Logos/red-chilli-paste-black.jpg);
    width: 100%;
    height: 250px;
    object-fit: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 50px;
    background-size: cover;
    background-position: center;
}

.HeroPoster h1 {
    color: var(--mdb-white) !important;
}

.aboutUsImg img {
    width: 100%;
    height: 495px;
    object-fit: cover;
    border-radius: 8px;
}

.form-outline .form-control::placeholder,
.form-outline .form-control::placeholder {
    opacity: 1 !important;
}

.ProductView-Img img {
    width: 360px;
    height: 75vh;
    object-fit: cover;
}

.ProductView-Img {
    display: flex;
    align-items: center;
    flex-direction: column
}

.cursor-pointer {
    cursor: pointer;
}

.card {
    background-color: var(--bg-10) !important;
}

.slideshow-items {
    width: 500px;
}

.slideshow-thumbnails {
    width: 100px;
}

#slideshow-items-container {
    display: inline-block;
    position: relative;
}

#lens {
    background-color: rgba(233, 233, 233, 0.4);
}

#lens,
#result {
    position: absolute;
    display: none;
    z-index: 1;
}

.slideshow-items {
    display: none;
}

.slideshow-items.active {
    display: block;
}

.slideshow-thumbnails {
    opacity: 0.5;
}

.slideshow-thumbnails.active {
    opacity: 1;
}

#lens,
.slideshow-items,
.slideshow-thumbnails,
#result {
    border: solid var(--light-grey-2) 1px;
}

.badge-primary path {
    color: var(--mdb-primary) !important;
}


.Rreadmore:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    /* border-bottom: 1px solid #f0f0f0; */
    height: 200px;
    background: linear-gradient(hsla(0, 0%, 100%, 0) 5%, var(--bg-10));
    bottom: 0;
}

.ProductcardImage {
    position: sticky;
    top: 100px;
}

.Products2 .img-fluid {
    width: 100%;
    height: 280px;
    object-fit: cover;
}

.list-group-item {
    background-color: var(--bg-100);
}

.navbar-brand img {
    width: 180px;
}

.navImg {
    background-image: url(../Assets/Logos/Group\ 2.png);
    width: 220px;
    height: 49px;
    background-size: 100%;
    background-repeat: no-repeat;
}

.customNav {
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: yellow;
    padding: 5px;
    width: 150px;
    z-index: 999;
}

.cardTextdesc {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

a {
    color: var(--text-100) !important;
}


.whatsapp {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
}

.whatsappMob {
    display: none;
}

.routing-border {
    border-bottom: 2px solid var(--mdb-gray-300);
    padding: 20px 0 20px 0;
}

a {
    cursor: pointer !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    color: var(--text-100);
}


.nav-link a {
    font-weight: 500;
}

.nav-link.active a {
    color: var(--mdb-primary) !important;
}