/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
    .padding-container {
        padding: 0px 10px;
    }

    .mainPage,
    .section-2 {
        height: auto !important;
        padding: 10px 0px;
        display: flex;
    }

    .Certifications2 .moblinediv {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        margin: 0px !important;
    }

    h1 {
        font-size: 2rem;
        text-align: center;
    }

    .mainPage h1 {
        padding-top: 10px;
    }

    .navbar {
        padding: 10px 10px;
    }

    p {
        margin-top: 0;
        margin-bottom: 0rem;
    }

    .mob-mt-2 {
        margin-top: 8px !important;
    }

    .mob-mt-3 {
        margin-top: 10px !important;
    }

    .mob-my-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem;
    }

    .nav-search {
        display: none !important;
    }

    .navbar {
        height: 77px !important;
    }

    .posterImage img {
        width: 100%;
    }

    .mob-d-flex {
        display: flex;
    }

    .mob-order-1 {
        order: 1;
    }

    .mob-order-2 {
        order: 2;
    }

    .mob-order-3 {
        order: 3;
    }

    .mob-order-4 {
        order: 4;
    }


    .posterImage {
        padding: 0px !important;
    }

    .posterImage p {
        text-align: justify !important;
        ;
    }

    .Products2 {
        width: 100%;
        margin-right: 0px;
        margin-top: 15px;
    }

    .Certificate {
        width: 100%;
        height: auto;
    }

    .Certificate small {
        position: relative;
        top: 10px;
    }

    .Certificate p {
        width: 100%;
        font-size: 13px;
        text-align: justify;
        text-indent: 50px;
    }

    .Certifications img {
        width: 200px;
        mix-blend-mode: multiply;
        object-fit: cover;
    }

    .HeroPoster {
        height: 150px;
        padding-left: 40px;
    }

    .card-text.p-2 {
        padding: 0 0 15px 0 !important;
    }

    .mob-py-5 {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .mob-mt-4 {
        margin-top: 1.5rem !important;
    }

    .w-mob-100 {
        width: 100% !important;
    }

    .d-mob-block {
        display: block !important;
    }

    .mob-ms-0 {
        margin-left: 0px !important;
    }

    .Certifications2 {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .mob-d-block {
        display: block !important;
    }

    .Products2 .img-fluid {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .whatsapp {
        display: none;
    }

    .whatsappMob {
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    .mob-w-100 {
        width: 100%;
    }

    .mob-w-50 {
        width: 50%;
    }

    .table>:not(caption)>*>* {
        padding: 1rem 0.6rem;
        background-color: var(--mdb-table-bg);
        border-bottom-width: 1px;
        box-shadow: inset 0 0 0 9999px var(--mdb-table-accent-bg);
    }

    .card-body {
        padding: 1rem;
    }

    .HeroPoster h1 {
        text-align: left;
    }

    .HeroPoster p {
        color: var(--primary-100) !important;
    }
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .padding-container {
        padding: 0px 10px;
    }

    .mainPage,
    .section-2 {
        height: auto !important;
        padding: 10px 0px;
        display: flex;
    }

    h1 {
        font-size: 2rem;
        text-align: center;
    }

    .mainPage h1 {
        padding-top: 10px;
    }

    p {
        margin-top: 0;
        margin-bottom: 0rem;
    }

    .mob-mt-2 {
        margin-top: 8px !important;
    }

    .mob-mt-3 {
        margin-top: 10px !important;
    }

    .mob-my-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem;
    }

    .posterImage img {
        width: 100%;
    }

    .mob-d-flex {
        display: flex;
    }

    .mob-order-1 {
        order: 1;
    }

    .mob-order-2 {
        order: 2;
    }

    .mob-order-3 {
        order: 3;
    }

    .mob-order-4 {
        order: 4;
    }


    .posterImage {
        padding: 0px !important;
    }

    .posterImage p {
        text-align: justify !important;
        ;
    }

    .Products2 {
        width: 100%;
        margin-right: 0px;
        margin-top: 15px;
    }

    .Certificate {
        width: 100%;
        height: auto;
    }

    .Certificate small {
        position: relative;
        top: 10px;
    }

    .Certificate p {
        width: 100%;
        font-size: 13px;
        text-align: justify;
        text-indent: 50px;
    }

    .Certifications img {
        width: 200px;
        mix-blend-mode: multiply;
        object-fit: cover;
    }

    .HeroPoster {
        height: 150px;
        padding-left: 40px;
    }

    .mob-py-5 {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .w-mob-100 {
        width: 100% !important;
    }

    .Certifications2 .moblinediv {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        margin: 0px !important;
    }

    .mob-d-block {
        display: block !important;
    }

    .Products2 .img-fluid {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .whatsapp {
        display: none;
    }

    .whatsappMob {
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    .table>:not(caption)>*>* {
        padding: 1rem 0.6rem;
        background-color: var(--mdb-table-bg);
        border-bottom-width: 1px;
        box-shadow: inset 0 0 0 9999px var(--mdb-table-accent-bg);
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .padding-container {
        padding: 0px 10px;
    }

    .mainPage,
    .section-2 {
        height: auto !important;
        padding: 10px 0px;
        display: flex;
    }

    h1 {
        font-size: 2rem;
        text-align: center;
    }

    .mainPage h1 {
        padding-top: 10px;
    }

    p {
        margin-top: 0;
        margin-bottom: 0rem;
    }

    .mob-mt-2 {
        margin-top: 8px !important;
    }

    .mob-mt-3 {
        margin-top: 10px !important;
    }

    .mob-my-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem;
    }

    .posterImage img {
        width: 100%;
    }

    .mob-d-flex {
        display: flex;
    }

    .mob-order-1 {
        order: 1;
    }

    .mob-order-2 {
        order: 2;
    }

    .mob-order-3 {
        order: 3;
    }

    .mob-order-4 {
        order: 4;
    }


    .posterImage {
        padding: 0px !important;
    }

    .posterImage p {
        text-align: justify !important;
        ;
    }

    .Products2 {
        width: 100%;
        margin-right: 0px;
        margin-top: 15px;
    }

    .Certificate {
        width: 100%;
        height: auto;
    }

    .Certificate small {
        position: relative;
        top: 10px;
    }

    .Certificate p {
        width: 100%;
        font-size: 13px;
        text-align: justify;
        text-indent: 50px;
    }

    .Certifications img {
        width: 200px;
        mix-blend-mode: multiply;
        object-fit: cover;
    }

    .HeroPoster {
        height: 150px;
        padding-left: 40px;
    }

    .mob-py-5 {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .w-mob-100 {
        width: 100% !important;
    }

    .Certifications2 {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .mob-d-block {
        display: block !important;
    }

    .Products2 .img-fluid {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .whatsapp {
        display: none;
    }

    .whatsappMob {
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    .table>:not(caption)>*>* {
        padding: 1rem 0.6rem;
        background-color: var(--mdb-table-bg);
        border-bottom-width: 1px;
        box-shadow: inset 0 0 0 9999px var(--mdb-table-accent-bg);
    }

    .mob-justify-content-center {
        justify-content: center;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    /* CSS rules for large devices */
}